
html {
  font-size: 62.5%;
}


body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', 'sans-serif';
  color: #444444;
  background: #024166;
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dashboard {
  margin-top: 250px;
  margin-left: 250px;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}


