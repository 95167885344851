.nav-bar {
    background: #242424;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo {
        display: block;
        padding: 8px 0;

        img {
            display: block;
            margin: 8px auto;
            width: 24px;
            height: auto;
        

        &.sub-logo {
            width: 50px;
        }
    }
}

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

    a {
        font-size: 22px;
        color: #4d4d4e;
        display: block;
        line-height: 51px;
        height: 51px;
        position: relative;
        text-decoration: none;

        i {
            transition: all 0.3s ease-out;
        }

        &:hover {
            color:#430402 ;       

            svg {
                opacity: 0;
            }

            &:after {
                opacity: 1;
            }
        }
            &:after {
                content: '';
                font-size: 9px;
                font-weight: bold;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                -webkit-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
            }

                &:first-child {
                    &:after{
                        content: 'HOME';
                    }
                }
            }
                a.about-link {
                    &:after {
                        content:'ABOUT'
                    }
                }

                a.contact-link {
                    &:after {
                        content:'CONTACT'
                    }
                }

                a.portfolio-link {
                    &:after {
                      content: 'PORTFOLIO';
                    }
                  }

                a.active {
                    svg {
                        color: #430402;
                    }
                }
            }

            ul {
                position: absolute;
                bottom: 20px;
                width: 100%;
                display: block;
                padding: 0;
                list-style: none;
                text-align: center;
                margin: 0;

                li {
                    a {
                        padding: 7px 0;
                        display: block;
                        font-size: 15px;
                        line-height: 16px;
                        color: #4d4d4e;
                        }

                    &:hover svg {
                        .anchor-icon {
                        color: #430402;
                        }
                    }
                    
                }
            }
        }

        .hamburger-icon, .close-icon {
            display: none;
          }

        @media screen and (max-width: 1200px) {
            .nav-bar { 
              background: transparent;
              position: initial;
              height: auto;
              min-height: auto;
          
               ul, nav {
                display: none;
               }
          
               nav {
                width: 100%;
                top: 0;
                left: 0;
                background: #181818;
                height: 100%;
                margin: 0;
                z-index: 2;
                position: fixed;
          
                &.mobile-show {
                  display: block;
                }
          
                a {
                  display: flex;
                  justify-content: left;
                  align-items: center;
                }
          
                a:after {
                  opacity: 1;
                  position: initial;
                  width: auto;
                  margin-left: 10PX;
                }
          
                a svg {
                  opacity: 1 !important;
                }
               }

          .hamburger-icon,
          .close-icon {
             position: absolute;
             top: 15px;
             right: 15px;
             display: block;
          }
       }
     }

        